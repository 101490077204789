import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, TooltipProvider } from '@mindpal-co/mindpal-ui';
import classNames from 'classnames';
import Link from 'next/link';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import LogoSymbol from '@/assets/logo-black.svg';
import LogoCVWhite from '@/assets/logo-mindpal.svg';
import SubscribeButton from '@/components/SubscribeButton';
import { TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useHiddenHeader } from '@/hooks/useHiddenHeader';

import Button from '../../Button';
import Icon from '../../Icon';
import Text from '../../Text';
import ConditionalActionButton from './ConditionalActionButton';
import MindPalCoHamburger from './Hamburger';

export type MenuType = {
  name: string;
  href: string;
  submenu?: {
    name: string;
    href: string;
  }[];
}[];

export const menu: MenuType = [
  {
    name: 'Candidate',
    href: '/',
    submenu: [
      {
        name: 'Resume Checker',
        href: '/resume-checker',
      },
    ],
  },
  {
    name: 'Recruiter',
    href: '/recruiter',
    submenu: [
      {
        name: 'Resume',
        href: '/resume',
      },
      {
        name: 'Job Matching',
        href: '/job-matching',
      },
      {
        name: 'MindPal ATS',
        href: '/ats',
      },
      {
        name: 'AI HR Bots',
        href: '/hr-bots',
      },
    ],
  },
  { name: 'Blog', href: 'https://mindpal.co/blog/' },
];

type Props = {
  theme?: 'dark' | 'light';
};
const Header: FC<Props> = ({ theme = 'dark' }) => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const { show, isOnTop } = useHiddenHeader();

  const preventDefault = (e: Event) => e.preventDefault();
  useEffect(() => {
    const enableScroll = () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('touchmove', preventDefault);
    };

    const disableScroll = () => {
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', preventDefault, {
        passive: false,
      });
    };

    if (isHamburgerOpen) disableScroll();
    else enableScroll();
    return () => {
      enableScroll();
    };
  }, [isHamburgerOpen]);

  return (
    <div
      className={classNames(
        'fixed left-0 transition-all top-0 flex w-full items-center z-50 bg-transparent opacity-100',
        {
          '!bg-neutral-1000/40': !isOnTop,
          '!opacity-0 pointer-events-none': !show,
        }
      )}
    >
      <div
        className={classNames(
          'hidden w-full flex-row justify-between px-24 py-10 lg:flex',
          {
            'text-neutral-1000': theme === 'light',
          }
        )}
      >
        <Link href="/">
          <div className="w-40">
            <LogoCVWhite />
          </div>
        </Link>
        <div className="flex flex-row items-center gap-8">
          {menu.map(({ name, href, submenu }) => (
            <div key={name} className="hover:text-primary-300">
              {!submenu && (
                <a href={href}>
                  <Text>{name}</Text>
                </a>
              )}
              {submenu && (
                <DropDownHeaderElement
                  theme={theme}
                  href={href}
                  name={name}
                  submenu={submenu}
                />
              )}
            </div>
          ))}
          <SubscribeButton size="md" className="text-white" />
          <ConditionalActionButton />
        </div>
      </div>
      <div className="flex w-full justify-end py-4 lg:hidden">
        <Link href="/">
          <div
            className={classNames(
              'absolute left-1/2 top-1/2 text-neutral-1000 -translate-x-1/2 -translate-y-1/2',
              {
                '!text-neutral-0': theme === 'dark',
              }
            )}
          >
            <LogoSymbol />
          </div>
        </Link>
        <Button
          onClick={() => setIsHamburgerOpen(true)}
          prefixIcon="material-symbols-light:menu"
          variant="unstyled"
          iconClassName="text-white !text-2xl"
        />
        <MindPalCoHamburger
          menu={menu}
          isOpen={isHamburgerOpen}
          onClose={() => setIsHamburgerOpen(false)}
        >
          <ConditionalActionButton />
          <SubscribeButton className="w-full" />
        </MindPalCoHamburger>
      </div>
    </div>
  );
};

export default Header;

type DropDownHeaderElementProps = {
  theme: 'dark' | 'light';
  name: string;
  href: string;
  submenu: {
    name: string;
    href: string;
  }[];
};
const DropDownHeaderElement: FC<DropDownHeaderElementProps> = ({
  name,
  submenu,
  href,
  theme,
}) => {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger className="[&[data-state*=open]_svg]:rotate-180 [&_svg]:transition-transform">
          <Link className="flex flex-row items-center gap-1" href={href}>
            <Text>{name}</Text>
            <Icon icon={faAngleDown} />
          </Link>
        </TooltipTrigger>
        <TooltipContent
          side="bottom"
          showArrow={false}
          className={classNames(
            'flex flex-col gap-2 w-40 rounded-lg border-none bg-jb-primary-500/50 px-2 py-4 backdrop-blur-3xl',
            {
              'bg-neutral-0': theme === 'light',
            }
          )}
        >
          {submenu.map(({ href: subHref, name: subName }) => (
            <a key={`${subName}${subHref}`} href={subHref}>
              <Button
                variant="unstyled"
                className={classNames(
                  'bg-transaprent w-full items-center !justify-start gap-2 !rounded-md !px-2 !py-1 hover:bg-jb-primary-500',
                  {
                    'hover:!bg-jb-neutral-300 !text-black': theme === 'light',
                  }
                )}
                size="long"
                tabIndex={-1}
              >
                <Text variant="jb-body-medium">{subName}</Text>
              </Button>
            </a>
          ))}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
