import {
  faInstagram,
  faLinkedin,
  faSquareFacebook,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';

import LogoCVWhite from '@/assets/logo-mindpal.svg';

import Icon from '../Icon';
import Text from '../Text';
import NewsletterForm from './NewsletterForm';

const footerNavigation = [
  {
    category: 'For Candidates',
    links: [
      { name: 'Resume Checker', href: '/resume-checker' },
      { name: 'Bullet Points', href: '/' },
      { name: 'Job Board', href: '/jobs' },
      { name: 'Pals Community', href: '/community' },
    ],
  },
  {
    category: 'For Recruiters',
    links: [
      { name: 'Log in', href: '/dashboard/auth/signin' },
      { name: 'Resume Generator', href: '/resume' },
      { name: 'Job Matching', href: '/job-matching' },
      { name: 'MindPal ATS', href: '/ats' },
      { name: 'AI HR Assistant', href: '/hr-bots' },
      { name: 'Recruiters Cafe Blog', href: '/blog' },
      { name: 'Pricing', href: '/dashboard/pricing' },
    ],
  },
  {
    category: 'Company',
    links: [
      { name: 'About Us', href: '/about-us' },
      { name: 'hello@mindpal.co', href: 'mailto:hello@mindpal.co' },
      { name: 'Newsroom', href: '/newsroom' },
      { name: 'Resources', href: '/resources' },
      { name: 'Careers', href: '/offers' },
      { name: '415 Mission St\n San Francisco, CA 94105', href: '#' },
    ],
  },
  {
    category: 'Terms',
    links: [
      { name: 'Privacy Policy', href: 'https://mindpal.co/privacy-policy/' },
      { name: 'Cookie Policy', href: 'https://mindpal.co/cookie-policy/' },
      {
        name: 'Terms of Service',
        href: 'https://mindpal.co/terms-of-service/',
      },
      {
        name: 'Responsible Disclosure',
        href: 'https://mindpal.co/responsible-disclosure',
      },
    ],
  },
];

const socials = [
  {
    icon: faInstagram,
    href: 'https://www.instagram.com/mindpal.co/',
  },
  {
    icon: faSquareFacebook,
    href: 'https://www.facebook.com/mindpal.co',
  },
  {
    icon: faLinkedin,
    href: 'https://www.linkedin.com/company/mindpal-co/posts/?feedView=all',
  },
  {
    icon: faTiktok,
    href: 'https://www.tiktok.com/@mindpal.co',
  },
  {
    icon: 'pajamas:twitter',
    href: 'https://x.com/mindpal_co',
  },
];

const Footer = () => {
  return (
    <div className="flex w-full flex-col gap-20 bg-jb-primary-600 p-10 lg:px-56 lg:py-20">
      <NewsletterForm />
      <div className="flex flex-col justify-between gap-10 lg:gap-20 xl:flex-row">
        <div className="w-48">
          <LogoCVWhite />
        </div>
        <div className="flex w-full flex-col gap-16 xl:max-w-2xl 2xl:max-w-4xl">
          <FooterNavigation />
          <div className="flex w-full flex-col justify-start lg:flex-row lg:gap-0">
            <div className="flex flex-1 flex-row items-center justify-start gap-2">
              {socials.map(({ href, icon }) => (
                <a
                  href={href}
                  key={href}
                  target="_blank"
                  className="flex aspect-square w-10 transition-transform hover:scale-150 lg:w-10"
                >
                  <Icon icon={icon} className="lg:!text-2xl" />
                </a>
              ))}
            </div>
            <div className="flex flex-1 items-start justify-end">
              <Text variant="jb-body-small">
                Copyright © MindPal {new Date().getFullYear().toString()}. All
                Right Reserved.
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

const FooterNavigation = () => {
  return (
    <div className="flex w-full flex-col justify-end gap-10 lg:flex-row lg:gap-4">
      {footerNavigation.map(({ category, links }) => (
        <div key={category} className="flex flex-1 flex-col gap-6">
          <Text variant="jb-body-medium" className="font-semibold">
            {category.toLocaleUpperCase()}
          </Text>
          <div className="flex flex-col gap-4">
            {links.map(({ name, href }) => (
              <a href={href} key={`${category}${name}`}>
                <Text variant="jb-body-small" className="whitespace-pre-line">
                  {name}
                </Text>
              </a>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
